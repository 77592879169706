<template>
  <div class="page">
     <van-nav-bar
      class="backBox"
      title="个人中心"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <header>
      <div class="item_wrap">
        <span class="title">登录方式</span>
        <span class="title_text"
          >{{ loginType === 1 ? '微信' : '支付宝' }}授权登录</span
        >
      </div>
      <div class="item_wrap">
        <span class="title">手机号</span>
        <span class="title_text">{{ authPhone }}</span>
      </div>
      <div class="phone_operate">
        <div class="havePhone" v-if="authPhone">
          <span class="phone_btn" @click="modifyPhone"
            >更换手机号<van-icon name="arrow"
          /></span>
        </div>
        <div class="noPhone" v-if="!authPhone">
          <span class="noPhoneTip">
            您还未绑定手机号，为方便给您提供更多服务，请先绑定手机号
          </span>
          <span class="phone_btn" @click="bindPhone"
            >绑定手机号<van-icon name="arrow"
          /></span>
        </div>
      </div>
    </header>
    <!-- 绑定手机 -->
    <bind-phone
      :show="show_bind_phone"
      :check-old-phone="checkOldPhone"
      @get-success="getBindPhoneSuccess"
      @get-on-close="getBindPhoneShow"
      @get-on-check-old="checkOldDone"
    ></bind-phone>

  </div>
</template>
<script>
import BindPhone from '../components/BindPhone.vue'
import { Toast } from 'vant'
import { mapState, mapGetters } from 'vuex'
import * as util from '../api/util'
import * as constant from '../api/constant'
import * as api from '../api/api'
import * as common from '../api/common'
import axios from 'axios'
export default {
  components: { BindPhone },
  name: 'UserPersonalCenter',
  data () {
    return {
      loginType: 1,
      show_bind_phone: false,
      checkOldPhone: false,
      authPhoneLocal: ''

    }
  },
  mounted () {
  },
  computed: {
    ...mapState({
      authPhone: state => state.phone.phone

    }),
    ...mapGetters(['openID', 'sid'])

  },
  methods: {

    onClickLeft () {
      this.$router.go(-1)
    },
    setting () {
      this.$router.push('/setting')
    },
    // 绑定手机号
    bindPhone () {
      this.show_bind_phone = true
    },
    // 更换手机号
    modifyPhone () {
      // todo 先验证原手机号 再绑定新手机号
      this.checkOldPhone = false
      this.show_bind_phone = true
    },
    getBindPhoneSuccess () {

    },
    getBindPhoneShow () {
      this.show_bind_phone = false
    },
    checkOldDone () {
      this.checkOldPhone = false
      // this.show_bind_phone = true
    }

  }
}
</script>
<style lang="scss" scoped>
.page {
  height: 100vh;
  background-color: #f8f7fc;
}
header {
  margin: 0.16rem;
  padding: 0.16rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .item_wrap {
    display: flex;
    justify-content: space-between;
    padding: 0.08rem 0;
  }
  .title {
    font-size: 0.16rem;
    color: #333;
  }
  .title_text {
    font-size: 0.14rem;
    color: #000;
  }

  .phone_operate {
    .phone_btn {
      color: #4797ea;
    }

    .havePhone {
      text-align: right;
      font-size: 0.14rem;
    }
    .noPhone {
      font-size: 0.14rem;
      .noPhoneTip {
        display: inline-block;
        text-align: left;
        color: #999;
        margin-left: 0.08rem;
      }
    }
  }
}
</style>
